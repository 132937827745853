@font-face {
  font-family: VestaboardWYSIWYG;
  src: url(./assets/Vestaboard.otf);
}

div.wysiwyg {
  user-select: none;
  max-width: 100%;
}

.wysiwyg .flex-1 {
  flex: 1 1 0;
}

.wysiwyg div {
  text-align: center;
}

.wysiwyg input[type="email"],
.wysiwyg input[type="number"],
.wysiwyg input[type="password"],
.wysiwyg input[type="tel"],
.wysiwyg input[type="text"],
.wysiwyg input[type="url"] {
  box-sizing: content-box;
}

.wysiwyg .container-fluid {
  position: relative;
  width: 100%;
  max-width: 1000px;
  padding-right: 0; /* 15px default */
  padding-left: 0; /* 15px default */
  margin-right: auto;
  margin-left: auto;
}

.wysiwyg .background {
  z-index: -100;
  width: 100%;
}

.wysiwyg .character-grid {
  position: absolute;
  top: 10%;
  left: 7%;
  right: 7%;
  bottom: 10%;
  /* padding: 1rem;
    font-size: 1rem;
    line-height: 1rem;
    font-family: Roboto;*/
}

.wysiwyg .column {
  padding: 1% 0.5%;
}

.wysiwyg .letter {
  position: relative;
  width: 100%;
  height: 100%;
}

.wysiwyg .letter .overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /*bottom: 0;*/
  pointer-events: none;
}

.wysiwyg .letter input {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  font-family: "VestaboardWYSIWYG", "Roboto", sans-serif;
  width: 100%;
  height: 80%;
  min-width: 0;
  min-height: 0;
  border: none;
  background-color: #2c2c2c;

  text-align: center;
  text-transform: uppercase;
  font-size: 120%;
  color: white;
  text-shadow: 0 0 0 white;
  border-radius: 0;
  padding: 0;
}

.wysiwyg .letter input:focus {
  outline: none;
  color: #ccc;
}

@media (max-width: 768px) {
  .wysiwyg .letter {
    border: 1px solid black;
  }

  .wysiwyg .letter .overlay {
    display: none;
  }

  .wysiwyg .letter input {
    height: 100%;
    font-size: 45%;
  }
}

* div {
  box-sizing: content-box;
}
