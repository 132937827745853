/*
  Tachyons enforces full coverage of border-box on all main elements: https://tachyons.io/docs/layout/box-sizing/,
  but this breaks label layout on material-ui's TextFields
 */

input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="url"] {
  box-sizing: content-box;
}

.modal-container {
  z-index: 2000 !important;
}
.modal-container + .MuiPopover-root {
  z-index: 2010 !important;
}
