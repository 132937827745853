@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/helveticaneue.woff2") format("woff2"),
    url("./fonts/helveticaneue.woff") format("woff"),
    url("./fonts/helveticaneue.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/helveticaneue-bold.woff2") format("woff2"),
    url("./fonts/helveticaneue-bold.woff") format("woff"),
    url("./fonts/helveticaneue-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/helveticaneue-medium.woff2") format("woff2"),
    url("./fonts/helveticaneue-medium.woff") format("woff"),
    url("./fonts/helveticaneue-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

html {
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: "HelveticaNeue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #242829 !important;
  overscroll-behavior-x: none;
}

body.transparent-body {
  background-color: transparent !important;
}

button {
  outline: 0 !important;
}

#root code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: rgba(245, 245, 247, 0.6);
}

.MuiButton-contained.Mui-disabled {
  background-color: transparent !important;
  border: solid 1px #6c757d !important;
  color: #6c757d !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}

.MuiButton-containedSecondary.Mui-disabled {
  background-color: #c3c3c3 !important;
  color: #ffffff !important;
  border-color: transparent !important;
  cursor: not-allowed !important;
}

.bg-light-gray {
  background-color: #171818;
}

#root {
  overflow-x: hidden;
  color-scheme: dark;
}

#root a {
  color: #3781cf;
}

#root .board-compose-input textarea {
  padding: 24px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.44;
  letter-spacing: 0.4px;
}

#root .board-compose-input textarea::placeholder {
  text-transform: none;
}

#root .b--light-gray {
  border-color: #202425 !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: transparent !important;
}

.MuiSelect-select.Mui-disabled,
.MuiButton-root.Mui-disabled {
  color: #7e878e;
}
